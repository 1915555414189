@import "../_scss/variables.scss";

.LoginDialog {
  .panel__background-wrapper {
    height: 75vw;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @media all and(min-width:480px) {
      height: 100vh;
    }

    .panel__form-container {
      position: absolute;
      top: 84px;
      background: $color-white;
      height: 600px;
      display: flex;
      max-width: 490px;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 5px;
      padding-block-start: 72px;
      width: -webkit-fill-available;

      .form__logo {
        height: 50px;
        width: 200px;
        margin-bottom: 74px;
      }

      @media all and(max-width:375px) {
        width: 90% !important;
        top: 110px !important;
      }

      @media all and(max-width:480px) {
        width: 80%;
        top: 205px;
      }
    }

    .panel__form {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0px 30px;

      @media all and(min-width:480px) {
        padding: 0px 62px;
      }

      .form__ {
        &input {
          border: none;
          border-bottom: 2px solid $color-black;
          background-color: $color-gray;
          margin-bottom: 20px;
          height: 48px;
          font-family: $RubikLight;
          font-size: 19px;
          line-height: 22.8px;
          color: $color-gray-2;
          padding-left: 16px;

          &:last-of-type {
            margin-bottom: 76px;
          }

          &::placeholder {
            color: $color-gray-2;
          }
        }

        &btn-submit {
          background: $color-black;
          color: $color-white;
          border: none;

          width: 120px;
          height: 36px;
          font-size: 14px;
          margin: 0 auto;
          margin-bottom: 27px;
        }
      }
    }

    .form__forgot {
      color: $color-primary;
      margin-bottom: 40px;
      font-family: $RubikRegular;
      font-size: 13px;
    }

    .form__register-copy,
    .form__register-btn {
      color: $color-black;
      line-height: 18px;
    }
  }
  .panel__text-container {
    background-color: $color-primary;
    text-align: center;
    color: $color-white;
    padding-bottom: 100px;
    padding: 100px 0;
    font-family: $RubikLight;

    @media all and(max-width:480px) {
      padding-top: 530px;
    }

    .panel__title {
      font-size: 64px;
      line-height: 77px;
      padding-bottom: 2rem;
    }
    .panel__body {
      font-size: 24px;
      line-height: 29px;
      margin: 0 auto !important ;
      max-width: 966px;

      b {
        font-weight: 700;
      }
    }
  }
}

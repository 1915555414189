@import "../_scss/variables.scss";

.Footer {
  background: $color-dark;
  color: $color-gray-2;
  font-family: $RubikLight;

  .container {
    @media all and(max-width:768px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .link {
    color: $color-gray-2;
  }

  .vertical-separator {
    padding: 0 10px;
    @media all and(max-width:768px) {
      display: none;
    }
  }

  br {
    @media all and(min-width:769px) {
      display: none;
    }
  }

  .copyright {
    color: $color-white;
  }

  .column {
    margin: 15px 0px;
    padding-left: 1rem;

    @media all and(min-width:769px) {
      border-left: 3px solid $color-dark-2;
      margin: 50px 0px;
    }

    img {
      vertical-align: baseline;
      margin-right: 8px;
    }

    &__title {
      color: $color-primary;
      font-family: $RubikMedium;
      margin-bottom: 2rem;
      font-size: 21px;

      @media all and (min-width: 992px) {
        font-size: 1.75rem;
      }
    }
    &__link {
      display: block;
      font-size: 16px;
      line-height: 26px;

      @media all and (min-width: 992px) {
        font-size: 18px;
      }

      &.has-padding {
        padding-bottom: 1.5rem;
      }
    }

    .phone {
      padding-bottom: 2rem;
    }
  }

  .bottom-bar {
    background-color: $color-black;

    @media all and(max-width:768px) {
      font-size: 14px;
      padding-top: 8px;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media all and(max-width:768px) {
        align-items: flex-start;
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .logo {
      margin: 14px 0;

      @media all and(max-width:768px) {
        margin-left: auto;
      }
    }
  }
}

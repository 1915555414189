@import "./font";
//////// Define this first for SCSS conversion - i.e. https://less2scss.awk5.com/

$base-line-height: 1.85714285714286em;

//////// Fonts
$RubikLight: "RubikLight";
$RubikMedium: "RubikMedium";
$RubikRegular: "RubikRegular";
$RubikBold: "RubikBold";
$MyriadProRegular: "MyriadProRegular";

//////// Colours

$color-white: #ffffff;
$color-black: #000000;
$color-primary: #ff0000;
$color-primary-2: #6e723e;
$color-primary-3: #a2a85e;
$color-gray: #ebebeb;
$color-gray-2: #9d9d9d;
$color-gray-3: #868686;
$color-gray-4: #6f6f6e;
$color-gray-5: #575756;
$color-gray-6: #d0d2d3;
$color-dark: #191919;
$color-dark-2: #121111;
$color-box-shadow: #00000029;

.BannerSliderPanel {
  .carousel-item .carousel-image {
    max-height: 330px;
    min-height: 330px;
    width: 100vw;
    display: block;
  }
  .carousel-indicators li {
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
}

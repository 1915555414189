/* Gets styles from Cart component */

@import "../../_scss/variables.scss";
.Cart,
.QuoteDetail,
.OrderDetail {
  background-color: $color-gray;
  min-height: 84vh;

  .on-edit-label {
    padding: 15px 0 3px;
    background-color: $color-primary-3;
    color: $color-white;

    span.quote-id {
      color: $color-primary;
    }
    span {
      color: $color-black;
    }
  }

  .list {
    &.container {
      @media all and (max-width: 991px) {
        padding: 0;
      }
    }

    &__heading {
      margin-top: 35px;
      margin-bottom: 40px;

      @media all and (max-width: 991px) {
        padding: 0 15px;
      }
    }

    &__content {
      @media all and (max-width: 991px) {
        flex-direction: column;
      }
    }

    &__items {
      width: 100%;
      background-color: $color-white;
      height: fit-content;

      @media all and (min-width: 992px) {
        width: 74%;
        padding: 0 30px;
        margin-right: 2%;
        margin-bottom: 30px;
      }

      .item-container + .item-container {
        border-top: solid 1px $color-gray-6;
      }
    }

    button {
      float: right;
    }

    &__right,
    &__left {
      display: flex;
      flex-direction: column;
      width: 90%;
      height: fit-content;
      margin: 30px 5%;
      background-color: $color-white;

      @media all and (min-width: 992px) {
        width: 24%;
        margin: 0;
      }
    }

    .buttons-container {
      border-bottom: 1px solid $color-black;
    }

    &__consult-order {
      display: flex;
      width: 39%;
      height: 64px;
      margin: 0 1% 4% 10%;
      place-items: center;
      font-family: $RubikLight;
      font-size: 12px;
      text-align: left;
      color: $color-white;
      padding: 15px 8px;
      line-height: 1.15;
      background-color: $color-primary-2;
      border: none;

      @media all and (min-width: 992px) {
        width: 45%;
        margin: 0 1% 4% 4%;
      }
    }

    .icon {
      margin-right: 5px;
    }
  }

  .item {
    margin: 0;
    padding: 20px 20px 20px;
    flex-direction: row;

    @media all and (min-width: 992px) {
      padding: 25px 0 25px 0;
    }

    .d-flex.w-100 {
      flex-direction: column;

      @media all and (min-width: 992px) {
        flex-direction: row;
      }
    }

    &__image--mobile,
    &__image--desktop {
      background-size: 75%;
      background-position: center;
      background-repeat: no-repeat;
    }

    &__image--mobile {
      display: block;
      width: 35%;
      height: 120px;
      padding: 8px 0 8px 8px;
      margin-top: 18px;

      @media all and (min-width: 992px) {
        display: none;
      }
    }

    &__image--desktop {
      width: 24%;
      height: 150px;
      margin-top: 24px;
      display: none;

      @media all and (min-width: 992px) {
        display: block;
      }
    }

    &__mid {
      width: 100%;
      margin-right: 0;
      padding-left: 12px;

      @media all and (min-width: 992px) {
        margin-right: 10%;
        padding-left: 0;
        width: 40%;
      }
    }

    &__end--desktop,
    &__end--mobile {
      flex-direction: column;
      width: 100%;

      @media all and (min-width: 992px) {
        display: flex;
        border-left: solid 2px $color-gray-4;
        width: 36%;
      }

      button.btn {
        border-radius: 0;
      }
    }

    &__end--desktop {
      display: none;
      padding-left: 22px;

      @media all and (min-width: 992px) {
        display: flex;
      }
    }

    &__end--mobile {
      display: flex;
      flex-direction: column;
      border-bottom: 8px solid $color-gray;
      padding: 0 20px 20px;

      @media all and (min-width: 992px) {
        display: none;
      }
    }

    &__tagline {
      @media all and (min-width: 992px) {
        margin-top: 24px;
      }
    }

    &__title {
      color: $color-primary;
    }

    &__ids {
      span {
        color: $color-gray-4;
      }

      .separator {
        display: none;
      }
    }

    &__price,
    &__total-price {
      font-family: $RubikMedium;
      font-size: 26px;
      margin-top: 15px;

      @media all and (min-width: 992px) {
        margin-top: 0;
        padding-bottom: 9px;
      }
    }

    &__price {
      color: $color-primary-2;
    }

    &__total-price {
      @media all and (max-width: 991px) {
        border-top: 2px solid $color-gray-4;
        padding: 13px 0 10px;
      }
    }

    &__currency,
    &__total-currency {
      font-size: 20px;
    }

    &__measure,
    &__total-measure {
      color: $color-black;
    }

    &__measure {
      margin-top: 25px;

      @media all and (max-width: 991px) {
        display: none;
      }
    }

    &__total-label {
      color: $color-gray-4;
    }

    &__delivery,
    &__state {
      align-items: flex-end;
      float: right;

      @media all and (min-width: 992px) {
        border-top: 1px solid $color-gray-4;
        margin-top: auto;
        float: left;
      }
    }

    &__total-quantity {
      align-items: center;
      flex-direction: row;

      @media all and (max-width: 991px) {
        border-top: 2px solid $color-gray-4;
        padding: 10px 0;
      }

      input {
        width: 60px;
        height: 32px;
        margin: 0 16px;
        padding-left: 13px;
        font-family: $RubikLight;
        color: $color-gray-4;
        background-color: $color-gray;
        border: none;
        outline: none;
      }
    }

    button {
      margin-top: 8px;
    }
    button.btn {
      padding: 7px;
    }
  }
  .btn-dark {
    background-color: $color-black;
  }
  .btn-danger {
    background-color: $color-primary;
  }
}


.QuoteDetail,
.OrderDetail {
  .list {
    &__heading--mobile {
      @media all and (min-width: 992px) {
        display: none;
      }

      .list__detail-bottom {
        width: 50%;
      }

      .d-flex {
        margin-top: 10px;
        align-items: center;
      }
    }
    &__heading {
      display: none;

      @media all and (min-width: 992px) {
        display: block;
      }
    }
    &__left {
      display: flex;
      margin-bottom: 50px !important;

      @media all and (min-width: 992px) {
        flex-direction: none;
      }
    }
    &__detail {
      display: none;
      background-color: $color-white;
      border-bottom: 20px solid $color-gray;

      @media all and (min-width: 992px) {
        display: block;
      }
    }
    &__detail,
    &__detail--mobile {
      padding: 25px;
      border-bottom: 20px solid $color-gray;

      .icon {
        margin-right: 0;
        margin-left: auto;
      }
    }
    &__detail-date {
      @media all and (min-width: 992px) {
        padding-bottom: 6px;
        margin-bottom: 17px;
        border-bottom: 1px solid $color-black;
        text-align: right;
      }
    }
    &__detail-id {
      border-bottom: 1px solid $color-black;
      color: $color-primary;
      padding-bottom: 15px;

      span:last-child {
        color: $color-black;
      }

      @media all and (max-width: 991px) {
        display: flex;
        justify-content: space-between;
        place-items: baseline;
      }
    }
    &__detail-name--label {
      padding-top: 10px;
    }
    &__detail-name {
      padding-bottom: 20px;
        border-bottom: 1px solid $color-black;
    }
    &__detail-state--label {
      padding-top: 10px;
    }
    &__detail-state {
      color: $color-primary;
      padding-bottom: 20px;
    }
    &__consult-order.btn.btn-secondary {
      width: 100%;
      height: 48px;
      margin: 0;
      padding: 20px;

      @media all and (min-width: 992px) {
        padding: 15px 12px;
      }

      span {
        margin-right: 15%;
      }
    }

    &__items {
      margin-right: 0;

      @media all and (min-width: 992px) {
        margin-left: 2%;
      }
    }

    &__content {
      @media all and (min-width: 992px) {
        flex-direction: row-reverse;
      }
    }
  }
  .item {
    &__delivery,
    &__state {
      height: 50px;

      .tagline-01 {
        color: $color-primary;
      }
    }
  }
}

.OrderDetail {
  .list {
    &__total-price {
      border-bottom: 1px solid $color-black;
    }
  }
}

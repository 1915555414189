@import "../../_scss/variables.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.ProductDetail {
  background-color: $color-gray;
  padding-bottom: 20px;

  .Breadcrumb {
    padding-top: 35px;
  }

  input {
    outline: none;
    box-shadow: none;
  }

  button.btn {
    box-shadow: none;
  }

  .detail {
    &.container {
      @media all and (max-width: 922px) {
        padding: 0;
      }
    }

    &__heading {
      margin-top: 35px;
      margin-bottom: 40px;

      @media all and (max-width: 991px) {
        padding: 0 15px;
      }
    }

    &__item {
      width: 100%;
      background-color: $color-white;
      padding: 23px;

      @media all and (min-width: 992px) {
        padding: 32px;
      }
    }
  }

  .container .item {
    margin: 20px 0;
    flex-direction: row;

    .d-flex.w-100 {
      flex-direction: column;

      @media all and (min-width: 992px) {
        flex-direction: row;
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      place-self: center;

      @media all and (min-width: 992px) {
        width: 28%;
      }
    }

    &__top-data--mobile {
      display: block;
      padding-bottom: 23px;
      margin-bottom: 23px;
      border-bottom: 1px solid $color-black;
      @media all and (min-width: 992px) {
        display: none;
      }
    }

    &__main-data {
      width: 100%;
      margin-right: 0;

      @media all and (min-width: 992px) {
        margin-left: 5%;
        padding-left: 0;
        width: 67%;
      }

      .item__tagline,
      .item__title,
      .item__ids {
        display: none;

        @media all and (min-width: 992px) {
          display: block;
        }
      }
    }

    &__bottom-data {
      width: 100%;
      margin-right: 0;
      padding-left: 12px;

      @media all and (min-width: 992px) {
        margin-right: 10%;
        padding-left: 0;
        width: 72%;
      }
    }

    &__end {
      width: 100%;
      padding-left: 12px;

      @media all and (min-width: 992px) {
        border-left: solid 2px $color-black;
        width: 36%;
      }

      button.btn {
        border-radius: 0;
      }
    }

    &__title {
      width: 80%;
      font-family: $RubikLight;
      font-size: 20px;
      color: $color-primary;

      @media all and (min-width: 992px) {
        width: 70%;
        font-size: 24px;
      }
    }

    &__ids {
      margin-top: 20px;

      span {
        color: $color-gray-4;
      }

      .separator {
        width: 6px;
        border-top: 1px solid $color-gray-4;
      }
    }

    &__price {
      font-family: $RubikMedium;
      font-size: 26px;
      margin-top: 23px;
      width: 100%;
      border-bottom: solid 1px $color-black;
      margin-bottom: 20px;

      @media all and (min-width: 992px) {
        width: 50%;
        padding-bottom: 9px;
        border-bottom: solid 1px $color-gray-6;
      }
    }

    &__currency {
      font-size: 20px;
    }

    &__measure {
      color: $color-gray-4;
    }

    &__delivery {
      width: 100%;

      @media all and (min-width: 992px) {
        width: 50%;
        margin-top: 13px;
      }
    }

    &__quantity {
      align-items: center;
      margin-top: 30px;
      flex-direction: row;
      width: 100%;

      @media all and (min-width: 992px) {
        width: 50%;
        margin-top: 80px;
      }

      input {
        height: 38px;
        margin-top: 7px;
        width: 100%;
        padding: 0 13px;
        font-family: $RubikLight;
        color: $color-gray-4;
        background-color: $color-gray;
        border: none;
        outline: none;

        @media all and (min-width: 576px) and (max-width: 991px) {
          width: 100%;
        }

        @media all and (min-width: 992px) {
          height: 32px;
          margin-top: 0;
        }
      }

      .quantity__label {
        margin-right: 15px;
      }

      .quantity__label + div {
        @media all and (min-width: 576px) and (max-width: 991px) {
          width: 28vw;
        }
        @media all and (min-width: 992px) {
          flex-grow: 1 !important;
        }
      }

      .cart-button--mobile {
        display: block;
        flex-grow: 1;
        margin-left: 15px;

        @media all and (min-width: 992px) {
          flex-grow: 0;
          display: none;
        }
      }
    }

    .cart-button--desktop {
      display: none;

      @media all and (min-width: 992px) {
        display: block;
        width: 50%;
      }
    }

    button.btn {
      margin-top: 8px;
      background-color: $color-black;
    }

    &__description {
      margin-top: 36px;
      border-top: solid 1px $color-gray-6;
      font-family: $RubikLight;
      font-size: 11px;

      @media all and (min-width: 992px) {
        font-size: 20px;
      }
    }

    .detail-title {
      font-family: $RubikRegular;
      font-size: 20px;
      margin: 24px 0 33px 0;
      color: $color-primary-2;

      @media all and (min-width: 992px) {
        font-size: 26px;
      }
    }

    &__specs {
      margin-top: 23px;
      border-top: solid 1px $color-gray-6;
      font-size: 11px;

      @media all and (min-width: 992px) {
        font-size: 14px;
      }
    }

    &__specs-table {
      @media all and (min-width: 1201px) {
        column-count: 2;
        -moz-column-count: 2;
        -webkit-column-count: 2;
      }

      tr {
        width: 100%;
        border: solid 1px $color-gray-6;
      }

      td {
        width: 50%;
        padding: 22px;
      }

      tr td:first-of-type {
        font-family: $RubikMedium;
        background-color: $color-gray;
        vertical-align: top;
      }
      tr td:last-of-type {
        font-family: $RubikLight;
      }
    }
  }

  .image-gallery {
    svg.image-gallery-svg {
      color: $color-black;
      width: 30px;
    }

    @media all and (max-width: 575px) {
      .image-gallery-thumbnails {
        margin-top: 30%;
        height: 80%;
      }
      .image-gallery-left-nav,
      .image-gallery-right-nav {
        padding: 0;
        position: absolute;
        left: -21%;
        transform: rotate(90deg);
      }
      .image-gallery-left-nav {
        top: -6%;
      }
      .image-gallery-right-nav {
        bottom: -44%;
      }
    }

    @media all and (min-width: 576px) and (max-width: 991px) {
      .image-gallery-thumbnails {
        margin-top: 55%;
        height: 80%;
      }
      .image-gallery-left-nav,
      .image-gallery-right-nav {
        padding: 0;
        position: absolute;
        left: -10%;
        transform: rotate(90deg);
      }
      .image-gallery-left-nav {
        top: -5%;
      }
      .image-gallery-right-nav {
        bottom: -44%;
      }
    }

    @media all and (min-width: 992px) and (max-width: 1200px) {
      .image-gallery-image {
        height: 100%;
      }
      .image-gallery-left-nav,
      .image-gallery-right-nav {
        top: 117%;
        padding: 0;
      }
      .image-gallery-thumbnails-wrapper {
        width: 75%;
        margin: auto;
      }
      .image-gallery-thumbnail {
        width: 70px;
      }
    }

    @media all and (min-width: 1201px) {
      .image-gallery-image {
        height: 100%;
      }
      .image-gallery-left-nav,
      .image-gallery-right-nav {
        top: 114%;
        padding: 0;
      }
      .image-gallery-thumbnails-wrapper {
        width: 80%;
        margin: auto;
      }
      .image-gallery-thumbnail {
        width: 70px;
      }
    }
  }
}

@import "../../_scss/variables.scss";

.confirm-modal {
  .modal-header {
    font-family: $RubikBold;
    color: $color-white;
    background-color: $color-black;
  }

  .close {
    display: none;
  }

  .modal-body {
    font-family: $RubikRegular;
  }

  .quote-id {
    color: $color-primary;
  }

  .btn {
    font-family: $RubikRegular;
  }

  .btn-danger {
    background-color: $color-primary;
  }

  .btn-dark {
    background-color: $color-black;
  }

  button.btn {
    padding: 0;
    border: none;
  }
}

@import "../../_scss/variables.scss";

.ProductFilter,
.filter {
  margin: 24px;

  @media all and (min-width: 992px) {
    margin: 0;
    margin-bottom: 10px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  button.dropdown-toggle::after {
    display: none !important;
  }

  button {
    width: 100%;
    margin-bottom: 8px;
    background-color: $color-black;
    text-align: left;
    margin-bottom: 0;
    border-radius: 0;
  }

  button[aria-expanded="true"] .accordion-icon {
    transform: rotate(-180deg);
  }

  .accordion-icon {
    float: right;
  }

  &__options {
    background-color: $color-white;
    padding: 26px 22px;

    input[type="text"] {
      padding: 10px;
      margin-bottom: 5px;
    }
    input[type="checkbox"] {
      margin-right: 5px;
    }
    .options-container {
      max-height: 200px;

      &.scrollable {
        overflow-y: scroll;
      }
    }
  }

  &__option {
    display: flex;
    align-items: center;
    padding: 6px 3px;
  }
}

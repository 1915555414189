@import "../_scss/variables.scss";

.CardGalleryPanel,
.CategoryList {
  background-color: $color-gray;
  padding: 20px 0;

  .Breadcrumb {
    margin-top: 35px;
  }

  .panel__heading {
    place-items: baseline;
  }

  .panel__cards {
    display: flex;
    flex-wrap: wrap;
  }

  .panel__button a {
    color: $color-gray-5;
    font-weight: bold;
    font-weight: 500;
    opacity: 1;

    &:hover {
      opacity: 0.8;
    }
  }

  .panel__bottom-bar {
    text-align: end;
  }

  .card {
    flex: 0 0 48%;
    margin-bottom: 4%;
    margin-right: 4%;
    opacity: 1;
    transition: 0.5s ease;
    background-color: $color-white;

    @media all and (max-width: 991px) {
      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    &:hover {
      opacity: 0.85;

      .card__image {
        background-size: 78%;
      }
    }
  }

  .three-per-row .card {
    @media all and (min-width: 992px) {
      flex: 0 0 32%;
      margin-bottom: 2%;
      margin-right: 2%;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  .four-per-row .card {
    @media all and (min-width: 992px) and (max-width: 1200px) {
      flex: 0 0 32%;
      margin-bottom: 2%;
      margin-right: 2%;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @media all and (min-width: 1201px) {
      flex: 0 0 23.5%;
      margin-bottom: 2%;
      margin-right: 2%;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }

  .card__title {
    font-size: 13px;
    padding: 13px 10px 10px;
    background-color: $color-black;
    color: $color-white;
    font-family: $RubikRegular;

    @media all and (min-width: 525px) {
      font-size: 16px;
      padding: 10px 15px;
    }
  }

  .card__image {
    height: 160px;
    background-color: $color-white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 75%;
    transition: 0.2s ease;

    @media all and (min-width: 525px) {
      height: 260px;
    }
  }

  .three-per-row .card__image {
    @media all and (min-width: 1200px) {
      height: 300px;
    }
  }

  .four-per-row .card__image {
    @media all and (min-width: 1200px) {
      height: 250px;
    }
  }
}

.CardGalleryPanel {
  .panel__heading {
    margin-top: 50px;
    margin-bottom: 35px;
  }
}

.CategoryList {
  .Breadcrumb + .container .panel__heading {
    margin-top: 35px;
  }
  .panel__heading {
    margin-top: 50px;
    margin-bottom: 35px;
  }
}

@import "../../_scss/variables.scss";

.ProductList {
  background-color: $color-gray;
  padding-bottom: 20px;

  .Breadcrumb {
    padding-top: 35px;
  }

  input {
    outline: none;
    box-shadow: none;
  }

  button.btn {
    box-shadow: none;
  }

  .list {
    &.container {
      @media all and (max-width: 991px) {
        padding: 0;
      }
    }

    &__heading {
      margin-top: 35px;
      margin-bottom: 40px;

      @media all and (max-width: 991px) {
        padding: 0 15px;
      }
    }

    &__sort {
      width: 100%;
      text-align: right;
      align-items: baseline;

      @media all and (max-width: 991px) {
        padding: 0 22px;
      }

      span {
        margin-right: 10px;
      }

      button.dropdown-toggle {
        width: 210px;
        height: 100%;
        padding: 10px 15px;
        align-items: center;
        text-align: left;
        border-radius: 0;
        border: none;
        font-family: $RubikMedium;
        font-size: 14px;
      }

      button.dropdown-toggle::after {
        display: none !important;
      }

      .dropdown-menu {
        position: absolute;
        display: block;
        max-height: 350px;
        width: 210px;
        padding: 6px 16px 20px;
        background-color: $color-gray;
        z-index: 9;
        border-radius: 0 0 10px 10px;
        box-shadow: 0px 3px 6px $color-box-shadow;
        transition: all 0.2s;
        visibility: hidden;
        overflow: hidden;
        opacity: 0;

        @media all and (max-width: 991px) {
          display: none;
          position: relative;
          width: 100%;

          &.show {
            display: block;
          }
        }
      }

      .dropdown.show .dropdown-menu {
        scroll-behavior: auto;
        overflow-y: auto;
        max-height: 350px;
        visibility: visible;
        opacity: 1;
      }

      .dropdown-item {
        padding: 10px 0;
        border-bottom: 1px $color-primary solid;
        width: 100%;
        text-align: left;
        font-family: $RubikRegular;
        font-size: 14px;
        white-space: normal;

        &:focus,
        &:active,
        &:hover {
          background-color: $color-gray;
          opacity: 1;
          color: $color-primary;
        }
      }

      .dropdown-icon {
        float: right;
        margin-left: auto;
      }
    }

    &__mb-filters-button {
      display: block;
      width: 80px;
      color: $color-primary;
      border: 1px solid $color-primary;
      border-radius: 0;

      @media all and (min-width: 992px) {
        display: none;
      }
    }

    &__filters {
      display: block;
      width: 23%;

      @media all and (max-width: 991px) {
        display: none;
        width: 100%;
        padding: 0;
        height: fit-content;

        &.pop-up--open {
          display: block;
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 999;
          background-color: $color-gray;
          overflow-y: scroll;
        }
      }
    }

    .pop-up-heading {
      padding: 12px 0;
      background-color: $color-white;
      @media all and (min-width: 992px) {
        display: none;
      }

      button.btn {
        margin-left: 12px;
        color: $color-primary;
      }

      img {
        margin: 0 14px 3px 0;
      }
    }

    &__items {
      width: 100%;
      background-color: $color-white;
      padding: 23px 0;

      @media all and (min-width: 992px) {
        width: 77%;
        padding: 23px 32px;
        margin-left: 32px;
      }

      .item:nth-child(2) {
        border-top: 1px solid $color-black;
      }
    }
  }

  .item {
    margin: 20px 0;
    padding: 20px 20px 20px;
    flex-direction: row;
    border-bottom: 8px solid $color-gray;

    @media all and (min-width: 992px) {
      padding: 25px 30px 25px 0;
      border: solid 1px $color-black;
    }

    .d-flex.w-100 {
      flex-direction: column;

      @media all and (min-width: 992px) {
        flex-direction: row;
      }
    }

    &__image--mobile,
    &__image--desktop {
      background-size: 75%;
      background-position: center;
      background-repeat: no-repeat;
    }

    &__image--mobile {
      display: block;
      width: 35%;
      height: 120px;
      padding: 8px 0 8px 8px;
      margin-top: 18px;

      @media all and (min-width: 992px) {
        display: none;
      }
    }

    &__image--desktop {
      width: 24%;
      height: 150px;
      margin-top: 24px;
      display: none;

      @media all and (min-width: 992px) {
        display: block;
      }
    }

    &__mid {
      width: 100%;
      margin-right: 0;
      padding-left: 12px;

      @media all and (min-width: 992px) {
        margin-right: 10%;
        padding-left: 0;
        width: 40%;
      }
    }

    &__end {
      width: 100%;
      padding-left: 12px;

      @media all and (min-width: 992px) {
        border-left: solid 2px $color-black;
        width: 36%;
      }

      button.btn {
        border-radius: 0;
      }
    }

    &__tagline {
      @media all and (min-width: 992px) {
        margin-top: 24px;
      }
    }

    &__title {
      color: $color-primary;
    }

    &__ids {
      margin-top: 20px;

      span {
        color: $color-gray-4;
      }

      .separator {
        width: 6px;
        border-top: 1px solid $color-gray-4;
      }
    }

    &__price {
      font-family: $RubikMedium;
      font-size: 26px;
      margin-top: 15px;

      @media all and (min-width: 992px) {
        margin-top: 0;
        padding-bottom: 9px;
        border-bottom: solid 1px $color-gray-6;
      }
    }

    &__currency {
      font-size: 20px;
    }

    &__measure {
      color: $color-gray-4;
    }

    &__delivery {
      @media all and (min-width: 992px) {
        margin-top: 13px;
      }
    }

    &__quantity {
      align-items: center;
      margin-top: 13px;
      flex-direction: row;

      input {
        width: 100%;
        height: 32px;
        padding: 0 13px;
        font-family: $RubikLight;
        color: $color-gray-4;
        background-color: $color-gray;
        border: none;
        outline: none;
      }

      .quantity__label {
        margin-right: 15px;
      }
    }

    button {
      width: 100%;
      margin-top: 8px;
      background-color: $color-black;
    }
  }
}

@import "./_scss/variables.scss";

/*
  Put all GLOBAL definitions here
*/

a,
a:hover {
  text-decoration: none;
}

/*
  Generic typography styles definitions
*/

.body-reg-01 {
  font-family: $RubikRegular;
  font-size: 14px;
}

.body-light-01 {
  font-family: $RubikLight;
  font-size: 14px;
}

.body-medium-01 {
  font-family: $RubikMedium;
  font-size: 14px;
}

.body-reg-02 {
  font-family: $RubikRegular;
  font-size: 16px;
}

.body-light-02 {
  font-family: $RubikLight;
  font-size: 16px;
}

.body-medium-02 {
  font-family: $RubikMedium;
  font-size: 16px;
}

.title-01 {
  font-family: $RubikRegular;
  font-size: 24px;

  @media all and (min-width: 992px) {
    font-size: 34px;
  }
}

.tagline-01 {
  font-family: $RubikBold;
  font-size: 14px;
}

.tagline-02 {
  font-family: $RubikBold;
  font-size: 16px;
}

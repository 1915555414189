@import "../../_scss/variables.scss";

.QuotesList,
.OrdersList {
  background-color: $color-gray;
  color: $color-black;
  padding-bottom: 50px;

  .panel__heading {
    padding: 35px 0;
  }

  .table {
    background-color: $color-white;
    padding: 35px;
    display: none;

    @media all and (min-width: 576px) {
      display: block;
    }
  }

  .table--mobile {
    background-color: $color-white;
    @media all and (min-width: 576px) {
      display: none;
    }

    .container {
      border-top: 1px solid $color-gray-2;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    thead:first-child {
      border-top: none;
    }
  }

  .item {
    &__delete-cell {
      background-color: $color-gray;
      text-align: right;
      @media all and (min-width: 576px) {
        text-align: center;
      }
    }
  }

  table {
    width: 100%;
  }

  td {
    border: 1px solid $color-gray-2;
  }

  thead {
    @media all and (max-width: 575px) {
      color: $color-white;
      background-color: $color-primary-2;
      border-top: 2px solid $color-black;
    }

    @media all and (min-width: 576px) {
      border-bottom: 2px solid $color-gray-2;
    }
  }

  thead td {
    padding: 25px;
  }

  tbody td {
    padding: 30px 25px;
  }

  a {
    width: 100%;
    color: $color-white;

    @media all and (min-width: 576px) {
      color: $color-primary;
    }

    div {
      width: 100%;

      &:hover {
        text-decoration: underline;
        opacity: 0.5;
      }
    }
  }

  img {
    @media all and (max-width: 575px) {
      margin-right: 6px;
    }
  }
}

.OrdersList {
  .item {
    & td:last-child {
      color: $color-primary;
    }
  }
}

@import "../../_scss/variables.scss";

.Breadcrumb {
  a {
    color: $color-gray-3;
    text-decoration: none;
  }

  ul {
    padding: 0;
    list-style-type: none;
    margin-bottom: 0.3em;
    display: inline-flex;
    .capitalize {
      text-transform: capitalize;
    }
    > li:before {
      content: ">";
      color: $color-gray-3;
      margin: 0 5px;
    }
    > li:nth-child(2):before {
      content: "|";
    }

    @media all and (max-width: 767px) {
      width: 100%;
      a:first-child {
        margin-top: 1px;
      }
      li {
        margin-left: 0 !important;
        font-size: 1em;
        padding-right: 5px;
      }
      li:not(:last-child) {
        display: none;
      }
    }
  }
}

@import "../_scss/variables.scss";

.SimpleTextPanel {
  background-color: $color-primary;
  text-align: center;
  color: $color-white;
  padding: 100px;
  font-weight: 100;

  .panel__title {
    font-size: 75px;
    padding-bottom: 2rem;
  }
  .panel__body {
    font-size: 26px;
    letter-spacing: 1px;

    b {
      font-weight: 700;
    }
  }
}

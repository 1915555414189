/* Gets styles from Cart component */

@import "../../_scss/variables.scss";

.OrderPrice {
  display: flex;
  flex-direction: column;
  
  @media all and (min-width: 992px) {
    flex-direction: none;
  }

  .price{

    &__box-title {
      padding: 8px 13px;
      color: $color-white;
      background-color: $color-black;
      text-transform: uppercase;
    }

    &__total-price {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 140px;
      font-family: $RubikRegular;
      font-size: 35px;
    }

    &__currency {
      font-size: 25px;
    }

    &__confirm-buy {
      display: flex;
      width: 39%;
      height: 64px;
      margin: 0 10% 6% 1%;
      place-items: center;
      font-family: $RubikMedium;
      font-size: 12px;
      text-align: left;
      padding: 15px 8px;
      line-height: 1.15;
      background-color: $color-primary;
      border: none;
      padding: 15px 8% 15px 8px;
      text-transform: uppercase;

      @media all and (min-width: 992px) {
        width: 45%;
        padding: 15px 8px;
        margin: 0 4% 4% 1%;
      }
    }

    &__save-quote {
      display: flex;
      width: 39%;
      height: 64px;
      margin: 0 1% 4% 10%;
      place-items: center;
      font-family: $RubikLight;
      font-size: 12px;
      text-align: left;
      color: $color-white;
      padding: 15px 8px;
      line-height: 1.15;
      background-color: $color-primary-2;
      border: none;

      @media all and (min-width: 992px) {
        width: 45%;
        margin: 0 1% 4% 4%;
      }
    }

    &__info {
      border-top: 8px solid $color-gray;
      padding: 14px 12px;
      font-family: $RubikLight;
      font-size: 12px;
    }

    &__delete-quote {
      background-color: $color-gray;
      padding: 13px 0 50px;

      button {
        padding: 7px;
        border-radius: 0;
      }
    }
  }
}
@import "../_scss/variables.scss";

.Header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;

  .navbar {
    display: block;
    width: 100%;
    background-color: $color-white;
    border-bottom: 10px $color-primary solid;
    margin-top: 0;
    padding: 18px 0;
    z-index: 99;

    @media all and (max-width: 991px) {
      position: relative;
      padding-top: 10px;
    }

    a.nav-link {
      display: flex;
      width: 100%;
    }

    .navbar-toggler {
      position: absolute;
      z-index: 9999;
      top: 15px;
      right: 15px;
      margin-top: 0;
      border: none;

      &:focus,
      &:active {
        box-shadow: none;
        outline: none;
      }
    }

    .navbar-collapse {
      flex-grow: 0;
    }

    .navbar-collapse:first-of-type {
      @media all and (max-width: 991px) {
        order: 2;
      }
    }

    .navbar-collapse:last-of-type {
      @media all and (max-width: 991px) {
        margin-top: 30px;
      }
    }
  }

  .header__logo {
    padding-top: 0;
    padding-bottom: 0;

    .logo {
      max-height: 65px;

      @media all and (max-width: 991px) {
        display: none;
      }
    }

    .mobile-logo {
      display: none;
      max-height: 45px;
      margin-left: 8px;

      @media all and (max-width: 991px) {
        display: block;
      }
    }
  }

  a.dropdown-toggle::after {
    display: none !important;
  }

  .dropdown-menu {
    position: absolute;
    display: block;
    max-height: 350px;
    width: 210px;
    padding: 25px 16px;
    background-color: $color-gray;
    z-index: 9;
    border-radius: 0 0 10px 10px;
    box-shadow: 0px 3px 6px $color-box-shadow;
    transition: all 0.2s;
    visibility: hidden;
    overflow: hidden;
    opacity: 0;

    @media all and (max-width: 991px) {
      display: none;
      position: relative;
      width: 100%;

      &.show {
        display: block;
      }
    }
  }

  .dropdown.show .dropdown-menu {
    scroll-behavior: auto;
    overflow-y: auto;
    max-height: 350px;
    visibility: visible;
    opacity: 1;
  }

  .dropdown-item {
    padding: 10px 0;
    border-bottom: 1px $color-primary solid;
    width: 100%;
    text-align: left;
    font-family: $RubikRegular;
    font-size: 14px;
    white-space: normal;

    &:focus,
    &:active,
    &:hover {
      background-color: $color-gray;
      opacity: 1;
      color: $color-primary;
    }
  }

  .dropdown-icon {
    float: right;
    margin-left: auto;
  }

  .header__categ-dropdown {
    width: 210px;

    &.show .dropdown-icon {
      transform: rotate(-180deg);
    }

    @media all and (min-width: 992px) and (max-width: 1200px) {
      width: 150px;
    }

    @media all and (max-width: 991px) {
      width: 100%;
    }

    button.btn {
      width: 100%;
      height: 100%;
      padding: 10px 15px;
      align-items: center;
      background-color: $color-black;
      font-size: 16px;
      font-family: $RubikRegular;
      color: $color-white;
      text-align: left;
      border-radius: 0;
      border: none;

      @media all and (min-width: 992px) {
        height: 48px;
      }

      &::after {
        display: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .header__user-dropdown {
    display: flex;
    width: 226px;
    border-top: 1px $color-gray-6 solid;
    border-bottom: 1px $color-gray-6 solid;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    font-family: $RubikRegular;
    color: $color-black;

    @media all and (max-width: 991px) {
      display: block;
      width: 100%;
    }

    &.show .dropdown-icon {
      transform: rotate(-180deg);
    }

    .user-icon {
      @media all and (max-width: 991px) {
        margin-left: 15px;
      }
    }

    img {
      margin-right: 13px;
    }

    .dropdown-menu {
      width: 226px;

      @media all and (max-width: 991px) {
        width: 100%;
      }
    }

    .dropdown-icon img {
      margin-right: 0;
      opacity: 0.3;

      @media all and (max-width: 991px) {
        margin-right: 15px;
      }
    }

    &:hover .dropdown-icon img {
      opacity: 1;
    }

    .dropdown-toggle:focus .dropdown-icon img {
      opacity: 1;
    }
  }

  .header__cart {
    @media all and (max-width: 991px) {
      position: absolute;
      top: 15px;
      right: 80px;
      right: 3.6rem;
      z-index: 999;
    }
  }

  .cart-count {
    position: absolute;
    background-color: $color-primary;
    border-radius: 25px;
    height: 23px;
    width: 23px;
    color: $color-white;
    margin-left: 18px;
    margin-top: -10px;
  }

  .header__search {
    padding: 0 33px 0 5px;
    outline: none !important;
    margin-left: auto;

    @media all and (max-width: 991px) {
      position: absolute;
      top: 10px;
      padding: 0 5px 0 5px;
      margin-right: 70px;
      margin-left: 15%;
      width: 55%;
    }

    input[type="search"]::-webkit-search-cancel-button {
      -webkit-appearance: none;

      height: 15px;
      width: 15px;
      background: url("../close--red.svg");
      background-size: cover;
    }

    form {
      display: flex;
      width: 100%;
      outline: none !important;
    }

    .input-group {
      outline: none !important;
    }

    input.form-control {
      height: 48px;
      border: none;
      padding-left: 27px;
      font-family: $RubikRegular;
      outline: none !important;
      background-color: $color-gray;
      border-radius: 0;

      &:focus {
        box-shadow: inset 0 -1px 0 #ddd;
      }
    }

    button.btn {
      padding-right: 22px;
      background-color: $color-gray;

      &:focus {
        box-shadow: inset 0 -1px 0 #ddd;
      }
    }

    .input-group-append .btn {
      border: none;
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }
}
/* Import webfonts custom for current project, using the `font-face` rule  */

@font-face {
  font-family: RubikLight;
  src: url("../fonts/RubikLight.ttf");
}
@font-face {
  font-family: RubikMedium;
  src: url("../fonts/RubikMedium.ttf");
}
@font-face {
  font-family: RubikRegular;
  src: url("../fonts/RubikRegular.ttf");
}
@font-face {
  font-family: RubikBold;
  src: url("../fonts/RubikBold.ttf");
}
@font-face {
  font-family: MyriadProRegular;
  src: url("../fonts/MyriadProRegular.otf");
}
